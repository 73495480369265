import { theme } from '@theme/theme';

import ExclamationIcon from 'components/SVGIcons/Toasts/ExclamationIcon';

import { IconWrapper, Info, Title, Wrapper } from './CustomToast.styled';

export type toastType = 'error' | 'warning' | 'info' | 'success';

interface CustomToast {
    content: React.ReactNode | string;
    type: toastType;
}

const {
    errorToastColor,
    infoToastColor,
    warningToastColor,
    successToastColor,
} = theme.colors;

const getColor = (type: toastType) => {
    switch (type) {
        case 'info':
            return infoToastColor;
        case 'warning':
            return warningToastColor;
        case 'error':
            return errorToastColor;
        case 'success':
            return successToastColor;
        default:
            return infoToastColor;
    }
};

const CustomToast: React.FC<CustomToast> = ({ content, type }) => {
    if (typeof content !== 'string') {
        return <div>{content}</div>;
    }

    return (
        <Wrapper>
            <IconWrapper>
                <ExclamationIcon
                    width={54}
                    height={54}
                    color={getColor(type)}
                />
            </IconWrapper>
            <Info>
                <Title>{content}</Title>
            </Info>
        </Wrapper>
    );
};

export default CustomToast;
