import { create } from 'zustand';

import { RuntimeConfig } from 'types/runtime-config';

interface ConfigProps {
    config: RuntimeConfig;
    setConfig: (value: RuntimeConfig) => void;
}

const useClientRuntimeConfig = create<ConfigProps>((set) => ({
    config: {},

    setConfig: (config) => set((store) => ({ ...store, config })),
}));

export default useClientRuntimeConfig;
