import axios, {
    AxiosProgressEvent,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from 'axios';
import Cookies from 'js-cookie';
import { Dispatch, SetStateAction } from 'react';

import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';

import { toastError } from 'components/CustomToast/customToastFunctions';

interface ExtendedConfig extends InternalAxiosRequestConfig {
    p0?: number;
}

interface ExtendedResponseConfig extends AxiosResponse {
    config: ExtendedConfig;
}

const localENV = process.env.NEXT_PUBLIC_SWAGGER_API;
const { NEXT_PUBLIC_SWAGGER_API: runtimeENV } = getCachedRuntimeConfig();

const axiosInstance = axios.create({
    baseURL: localENV || runtimeENV || '',
});

export const onUploadProgressConfig = (
    e: AxiosProgressEvent,
    setProgress: Dispatch<SetStateAction<number>>
) => {
    if (!e.total) return;

    const percentCompleted = Math.round((e.loaded * 100) / e.total);
    setProgress(percentCompleted);
};

axiosInstance.interceptors.request.use(
    (config: ExtendedConfig) => {
        const CSRFToken = Cookies.get('csrftoken');

        config.headers['X-CSRFToken'] = CSRFToken;
        config.withCredentials = true;
        config.p0 = performance.now();

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    async (res: ExtendedResponseConfig) => {
        if (res.config.p0) {
            const latency = performance.now() - res.config.p0;
            const minimumDelay = 500;
            const shouldNotDelay = minimumDelay < latency;
            if (shouldNotDelay) {
                return res;
            }
            const remainder = minimumDelay - latency;
            const [responseWithDelay] = await Promise.all([
                res,
                new Promise((resolve) => setTimeout(resolve, remainder)),
            ]);
            return responseWithDelay;
        }

        return res;
    },
    (error) => {
        if (error.response.status === 503) {
            return toastError('Sorry, we are in Read-Only mode');
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
