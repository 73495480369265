import { styled } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import { theme } from '@theme/theme';

export const StyledToastContainer = styled(ToastContainer)(() => ({
    '--toastify-color-info': theme.colors.infoToastColor,
    '--toastify-color-warning': theme.colors.warningToastColor,
    '--toastify-color-error': theme.colors.errorToastColor,
    '--toastify-icon-color-info': 'var(--toastify-color-info)',
    '--toastify-icon-color-warning': 'var(--toastify-color-warning)',
    '--toastify-icon-color-error': 'var(--toastify-color-error)',
    '--toastify-toast-width': '532px',
    '--toastify-toast-background': theme.colors.secondaryColor_8,
    '--toastify-toast-min-height': '64px',
    '--toastify-toast-max-height': '800px',
    '--toastify-color-progress-info': 'var(--toastify-color-info)',
    '--toastify-color-progress-warning': 'var(--toastify-color-warning)',
    '--toastify-color-progress-error': 'var(--toastify-color-error)',

    '& .Toastify__toast--error': {
        border: 'var(--toastify-color-error) 1px solid',
    },
    '& .Toastify__toast--info': {
        border: 'var(--toastify-color-info) 1px solid',
    },
    '& .Toastify__toast--warning': {
        border: 'var(--toastify-color-warning) 1px solid',
    },
    '& .Toastify__toast--success': {
        border: 'var(--toastify-color-success) 1px solid',
    },
    '&div .Toastify__toast': {
        borderRadius: '12px',
    },
}));
