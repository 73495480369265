import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { sessionStorageKeys } from 'constants/localStorageKeys';

type UTMTagKeys = (typeof utmTags)[number];
type UTMParams = Partial<Record<UTMTagKeys, string>>;

const { utmStorageKey } = sessionStorageKeys;
const utmTags = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'utm_placement',
] as const;

const defaultStoreValues = utmTags.reduce((acc, tag) => {
    acc[tag] = '';
    return acc;
}, {} as UTMParams);

export const clearUtmStorage = () => {
    if (!global.window) return;
    sessionStorage.removeItem(utmStorageKey);
};

export const useGetUtmSessionTags = (): UTMParams => {
    if (!global.window) return {};

    const data = sessionStorage.getItem(utmStorageKey);

    return data ? JSON.parse(data) : defaultStoreValues;
};

const useUTMHandler = () => {
    const { replace, asPath } = useRouter();

    const [basePath, queriesStr] = asPath.split('?');

    const queries = queriesStr?.split('&').reduce(
        (acc, query) => {
            const [key, value] = query.split('=');

            if (!key || !value) return acc;

            acc[key] = value;
            return acc;
        },
        {} as Record<string, string>
    );

    const handleRouteChange = () => {
        const filteredQuery = pick(queries, utmTags);

        if (isEmpty(filteredQuery)) return;

        sessionStorage.setItem(utmStorageKey, JSON.stringify(filteredQuery));

        const modifiedQuery = { ...queries };

        utmTags.forEach((tag) => delete modifiedQuery[tag]);

        replace({ pathname: basePath, query: modifiedQuery }, undefined, {
            shallow: true,
        });
    };

    useEffect(handleRouteChange, [asPath]);
};

export default useUTMHandler;
