import { useState } from 'react';

import { Flags } from 'api/getServerFlags';
import { theme } from 'theme/theme';

import AttentionIcon from 'components/SVGIcons/AttentionIcon';
import CloseIcon from 'components/SVGIcons/CloseIcon';

import {
    Background,
    Content,
    ContentBody,
    Description,
    Title,
    Wrapper,
} from './MaintenanceBanner.styled';

const iconColor = theme.colors.primaryColor;

const readOnly =
    'We are currently updating our platform based on features and suggestions you can explore our platform in Read-Only mode for the time being.';

export const serverIsDown =
    'We are currently updating our platform based on features and suggestions. Our platform is in Maintenance mode for the time being.';

export interface MaintenanceProps extends Flags {
    canBeClosed?: boolean;
}

const MaintenanceBanner: React.FC<MaintenanceProps> = ({
    maintenance_mode,
    readonly_mode,
    canBeClosed = false,
}) => {
    const [isClosed, setIsClosed] = useState(false);
    const isServerUnderMaintenance = !!maintenance_mode || !!readonly_mode;

    if (isClosed || !isServerUnderMaintenance) return null;

    return (
        <Background>
            <Wrapper>
                <AttentionIcon width={24} color={iconColor} />

                <Content>
                    <ContentBody>
                        <Title>Hello Professionals!</Title>
                        <Description>
                            {maintenance_mode && serverIsDown}

                            {readonly_mode && readOnly}
                        </Description>
                        <Description>Check back soon!</Description>
                    </ContentBody>

                    {canBeClosed && (
                        <CloseIcon
                            width={14}
                            isCursorPointer
                            color={iconColor}
                            onClick={() => setIsClosed(true)}
                        />
                    )}
                </Content>
            </Wrapper>
        </Background>
    );
};

export default MaintenanceBanner;
