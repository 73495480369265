import AppProviders from 'app/AppProviders';
import { AppProps } from 'next/app';
import React from 'react';
import { SWRConfig, SWRConfiguration } from 'swr';

import Favicon from 'components/Favicon';

import '../index.scss';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'react-toastify/dist/ReactToastify.css';

const swGlobalConfig: SWRConfiguration = {
    onErrorRetry: (error) => {
        if ([404, 500, 403, 502].includes(error.status)) return;
    },

    errorRetryCount: 5,
};

function MyApp({ Component, pageProps }: AppProps) {
    return (
        <SWRConfig value={swGlobalConfig}>
            <AppProviders>
                <Favicon />
                <Component {...pageProps} />
            </AppProviders>
        </SWRConfig>
    );
}

export default MyApp;
