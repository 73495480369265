import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { useCompanyGrant } from '@hooks/requests/useCompanyGrant';
import getMyData, { isAnonymousResponse } from 'api/getMyData';
import { getGroupGrant } from 'api/groups/getGroupGrant';
import { useUserData } from 'state/userData';

const userFetcher = async () => {
    const { data } = await getMyData();
    return data;
};

const fetchGroupGrant = async (slug: string) => {
    const data = await getGroupGrant(slug);

    return data;
};

const FetchUserData = () => {
    const { query, push } = useRouter();
    const { setUserData, setLoginStatus, setIsFetched } = useUserData();

    const [isRedirectedToCvUpload, setIsRedirectedToCvUpload] = useState(false);

    const redirectHandlerCV = (withCV: boolean) => {
        if (withCV || isRedirectedToCvUpload) return;

        const nextPageLink = query.next as string;

        if (nextPageLink) {
            push(`/sign-up/talent/3?next=${nextPageLink}`);
        } else {
            push('/sign-up/talent/3');
        }

        setIsRedirectedToCvUpload(true);
    };

    const { data: userData, isLoading } = useSWR('/me/', userFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 1200000,
    });

    const isLoggedIn = !isAnonymousResponse(userData);

    const groupSlug = isLoggedIn
        ? userData?.active_company?.company_groups.slug || ''
        : '';

    const { data: groupGrant } = useSWR(
        groupSlug ? `groupGrant/${groupSlug}` : null,
        () => fetchGroupGrant(groupSlug)
    );

    const companySlug = isLoggedIn ? userData?.active_company?.slug || '' : '';

    const { data: companyGrant } = useCompanyGrant(companySlug);

    useEffect(() => {
        if (isLoading) return;

        if (!userData || isAnonymousResponse(userData)) {
            setLoginStatus(false);
            Sentry.setContext('User Info', { userType: 'not logged in' });
        } else {
            setLoginStatus(true);

            const talentWithoutCV =
                userData.type === 'talent' &&
                !userData.profile.cv_file &&
                !isRedirectedToCvUpload;

            if (talentWithoutCV) {
                push('/sign-up/talent/2');
                setIsRedirectedToCvUpload(true);
            }

            setUserData({
                firstName: userData.first_name,
                lastName: userData.last_name,
                email: userData.email,
                phoneNumber: userData.phone_number,
                username: userData.username,
                location: userData.location,
                type: userData.type,
                role: userData.role,
                id: userData.id,
                profile: userData.profile,
                hasSmsVerification: userData.oob,
                hasAuthenticator: userData.otp,
                companySlug: userData.company_id,
                typeGroups: userData.groups,
                initials: userData.initials,
                activeCompany: userData.active_company,
                defaultCompany: userData.default_company,
                groupRoles: groupGrant?.roles,
                recruiterId: groupGrant?.recruiter_id,
                companyRoles: companyGrant?.roles,
            });

            const sentryLogData = {
                firstName: userData.first_name,
                lastName: userData.last_name,
                email: userData.email,
                userType: userData.type,
                userSlug: userData.slug || userData.anonymous_profile?.slug,
            };

            Sentry.setContext('User Info', sentryLogData);

            const isTalent = userData?.type === 'talent';
            const withCV = !!userData?.profile?.cv_file;

            if (isTalent) {
                redirectHandlerCV(withCV);
            }
        }

        setIsFetched(true);
    }, [
        userData,
        isLoading,
        groupGrant?.roles,
        companyGrant?.roles,
        groupGrant?.recruiter_id,
    ]);

    return null;
};

export default FetchUserData;
