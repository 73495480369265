import Head from 'next/head';

import useEnvironment from 'hooks/useEnvironment';

const Favicon = () => {
    const { isGloPros } = useEnvironment();
    const path = `favicon/${isGloPros ? 'GloPros' : 'GlobalProfessionals'}`;
    const content = isGloPros ? 'GloPros' : 'GlobalProfessionals';

    return (
        <Head>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`/${path}/apple-touch-icon.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={`/${path}/favicon-32x32.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={`/${path}/favicon-16x16.png`}
            />
            <link rel="manifest" href={`/${path}/site.webmanifest`} />
            <link
                rel="mask-icon"
                href={`/${path}/safari-pinned-tab.svg`}
                color="#5bbad5"
            />
            <meta name="apple-mobile-web-app-title" content={content} />
            <meta name="application-name" content={content} />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
};

export default Favicon;
