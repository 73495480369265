import { create } from 'zustand';

import { UserLocation, UserProfile } from 'api/getMyData';
import { Organization } from 'api/organizations/getClientOrganizations';
import { CompanyRole } from 'api/organizations/getOrganizationMembers';
import { GroupRoles } from 'api/organizations/inviteNewOrganizationMember';
import { UserTypeGroups } from 'types/pageTypes';

export type AccountPrivacy = 'public' | 'anonymous';
export type UserRole =
    | 'professional'
    | 'client'
    | 'client admin'
    | 'partner'
    | null;

interface UserData {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    initials: string | null;
    phoneNumber: string | null;
    username: string | null;
    location: UserLocation | null;
    // deprecated, but still can be used in some cases; use typeGroups instead;
    type: 'client' | 'talent' | 'expert' | null;
    role: UserRole;
    id: number | null;
    profile: Partial<UserProfile> | null;
    hasSmsVerification: boolean;
    hasAuthenticator: boolean;
    companySlug: string | null;
    privacy: AccountPrivacy;
    typeGroups: UserTypeGroups | null;
    activeCompany: Organization | null;
    company_id?: string;
    defaultCompany: Organization | null;
    companyRoles: CompanyRole[] | null;
    groupRoles: GroupRoles | null;
    recruiterId?: number | null;
}

interface UserProfileState {
    isLoggedIn: boolean;
    isFetched: boolean;
    user: UserData;
    setUserData: (data: Partial<UserData>) => void;
    setLoginStatus: (isLogged: boolean) => void;
    setIsFetched: (isFetched: boolean) => void;
}

export const useUserData = create<UserProfileState>((set) => ({
    isLoggedIn: false,
    isFetched: false,

    user: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        username: null,
        initials: null,
        location: null,
        type: null,
        role: null,
        id: null,
        profile: null,
        hasAuthenticator: false,
        hasSmsVerification: false,
        companySlug: null,
        privacy: 'public',
        typeGroups: null,
        activeCompany: null,
        defaultCompany: null,
        groupRoles: null,
        companyRoles: null,
        recruiterId: null,
    },

    setUserData: (newUserData) =>
        set((state) => ({ user: { ...state.user, ...newUserData } })),

    setLoginStatus: (isLogged) => set({ isLoggedIn: isLogged }),

    setIsFetched: (isFetched) => set({ isFetched: isFetched }),
}));
