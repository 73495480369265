import { createTheme } from '@mui/material/styles';

import { pxToRem } from 'helpers/pxToRem';
import { Breakpoints } from 'types/breakpoints';

const ReemKufi = 'Reem Kufi';
const Roboto = 'Roboto';
const Inter = 'Inter';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: false; // removes the `xs` breakpoint
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        desktop: true;
    }

    interface Typography {
        link: React.CSSProperties;
    }

    interface TypographyOptions {
        link?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        link: true;
    }
}

const fonts = {
    h1_headline: {
        fontFamily: ReemKufi,
        fontStyle: 'bold',
        fontWeight: '700',
        fontSize: pxToRem(58),
        lineHeight: pxToRem(69),
        letterSpacing: '1px',
    },

    h2_headline: {
        fontFamily: ReemKufi,
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: pxToRem(52),
        lineHeight: pxToRem(56),
        letterSpacing: '1px',
    },

    h3_headline: {
        fontFamily: ReemKufi,
        fontStyle: 'normal',
        fontSize: pxToRem(38),
        lineHeight: pxToRem(36),
        letterSpacing: '1px',
    },

    h3_title: {
        fontFamily: ReemKufi,
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: pxToRem(42),
        lineHeight: pxToRem(42),
    },

    h4_headline: {
        fontFamily: ReemKufi,
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: pxToRem(32),
        lineHeight: pxToRem(32),
        letterSpacing: '0.18px',
    },

    h5_headline: {
        fontFamily: ReemKufi,
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: pxToRem(26),
        lineHeight: pxToRem(32),
        letterSpacing: '0.5px',
    },

    h6_headline: {
        fontFamily: ReemKufi,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(22),
        lineHeight: pxToRem(24),
        letterSpacing: '0.15px',
    },

    h7_headline: {
        fontFamily: ReemKufi,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(18),
        lineHeight: pxToRem(24),
        letterSpacing: '0.15px',
    },

    subtitle_1: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(16),
        lineHeight: pxToRem(38),
        letterSpacing: '0.5px',
    },

    subtitle_2: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(24),
        letterSpacing: '0.5px',
    },

    body_1: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
        letterSpacing: '0.5px',
    },

    body_2: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
        letterSpacing: '0.5px',
    },

    body_3: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(14),
        letterSpacing: '0.5px',
    },

    body_4: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(16),
        letterSpacing: '0.5px',
    },

    body_5: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(22),
        lineHeight: pxToRem(32),
        letterSpacing: '1px',
    },

    body_6: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(28),
        lineHeight: pxToRem(38),
        letterSpacing: '1px',
    },

    caption: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: pxToRem(12),
        lineHeight: pxToRem(12),
    },

    caption_2: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(12),
        lineHeight: pxToRem(16),
    },

    boldCaption: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(14),
    },

    inter: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
    },

    inter500: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
    },

    interBig: {
        fontFamily: Inter,
        fontSize: pxToRem(18),
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: pxToRem(24),
    },

    midCaption: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(16),
    },

    bigCaption: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
    },

    link: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(16),
        lineHeight: pxToRem(18),
        letterSpacing: '0.08px',
    },

    link_button: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
        letterSpacing: '0.005em',
    },

    link_button_2: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(18),
        lineHeight: pxToRem(24),
        letterSpacing: '0.005em',
    },

    button_text: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: pxToRem(16),
        lineHeight: pxToRem(16),
        letterSpacing: '1.25px',
    },

    bold_text: {
        fontFamily: Roboto,
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(16),
        letterSpacing: '0.5px',
    },
};

export type Fonts = typeof fonts;

const colors = {
    primaryColor: '#0053CD',
    primaryColor1: '#021B38',
    primaryColor_3: '#C5E1FF',
    primaryColor_4: '#ECF5FF',
    secondaryColor_basic: '#E8B600',
    secondaryColor_2: '#A9B8CF',
    secondaryColor_3: '#F3F5F9',
    secondaryColor_4: '#E3E7EC',
    secondaryColor_5: '#E5E7EB',
    secondaryColor_6: '#374151',
    secondaryColor_7: '#92969B',
    secondaryColor_8: '#F9FBFD',
    secondaryColor_9: '#C1C1C1',
    secondaryColor_10: '#F1F1F1',
    secondaryColor_11: '#063B83',
    secondaryColor_12: '#000000',
    secondaryColor_13: '#C5E1FF',
    secondaryColor_15: '#F9D656',
    secondaryColor_16: '#CACACA',
    secondaryColor_17: '#FFFAED',
    secondaryColor_18: '#616161',
    secondaryColor_19: '#C4C4C4',
    secondaryColor_20: '#053E92',
    secondaryColor_21: '#26417E',
    secondaryColor_22: '#888B93',
    secondaryColor_23: '#111827',
    secondaryColor_24: '#D8D9DC',
    secondaryTextColor_3: '#434343',
    footerGlobalProfessionals: '#243F80',
    primaryText: '#0D102B',
    secondaryText: '#6E6F7D',
    white: '#FFFFFF',
    borderColor: '#CFD8DC',
    checkBoxPrimary: '#BBBFC1',
    dropDownHover: '#EBF5FF',
    dividerColor: '#CED6E1',
    error: '#B00020',
    caption: '#676767',
    blueAnchor: '#408EF9',
    errorText: '#d32f2f',
    black: '#000000',
    placeholderColor: '#ACACAC',
    hoverColor: '#84B5F8',
    dirtyWhite: '#EBEEF1',
    errorToastColor: '#F88078',
    infoToastColor: '#0053cd',
    warningToastColor: '#e8b600',
    successToastColor: '#07bc0c',
    invitationColor: '#92400E',
    tableBorder: '#E5E5E5',
};

export type Colors = typeof colors;

declare module '@mui/material/styles' {
    interface Theme {
        colors: Colors;
        fonts: Fonts;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        colors?: Colors;
        fonts?: Fonts;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides
        extends Record<keyof Fonts, true> {}
}

export const theme = createTheme({
    breakpoints: {
        values: {
            mobile: Breakpoints.MOBILE,
            tablet: Breakpoints.TABLET,
            desktop: Breakpoints.DESKTOP,
        },
    },
    fonts: fonts,
    typography: fonts,

    colors: colors,
});
