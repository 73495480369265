import useSWR from 'swr';

import getClientGrant from 'api/organizations/getClientGrant';

const clientGrantFetcher = async (companySlug: string | null) => {
    if (!companySlug) return;

    const { data } = await getClientGrant(companySlug);
    return data;
};

export const useCompanyGrant = (companySlug: string | null) =>
    useSWR(companySlug ? `client-grant-${companySlug}` : null, () =>
        clientGrantFetcher(companySlug)
    );
