import axiosInstance from '../axiosConfig';
import { TeamMember } from '../workspaces/getMembers';

export interface GroupGrant extends Omit<TeamMember, 'roles'> {
    roles: [
        'group_administrator',
        'group_creator',
        'group_owner',
        'group_member',
    ];
}

export const getGroupGrant = async (slug: string) => {
    const { data } = await axiosInstance.get<GroupGrant>(
        `/wt/api/v1/groups/${slug}/grant/`
    );

    return data;
};
