import axiosInstance from '../axiosConfig';
import { CompanyRole } from './getOrganizationMembers';

interface ClientGrant {
    user_id: number;
    id: number;
    photo: {
        title: string;
        height: number;
        width: number;
        url: string;
    };
    first_name: string;
    last_name: string;
    roles: CompanyRole[];
    email: string;
    member_of: string;
    location: Record<string, any>;
    phone_number: string;
    job: string[];
    is_invitation_pending: boolean;
    is_archived: boolean;
    is_deleted: boolean;
}

const getClientGrant = (slug: string) => {
    return axiosInstance.get<ClientGrant>(`/wt/api/v1/company/${slug}/grant/`);
};

export default getClientGrant;
