import axios from 'axios';
import isEqual from 'lodash/isEqual';
import { Fragment, ReactNode, useEffect } from 'react';
import useSWR from 'swr';

import {
    getCachedRuntimeConfig,
    setCachedRuntimeConfig,
} from 'utils/runtimeConfigUtils';

import { RuntimeConfig } from '../types/runtime-config';
import useClientRuntimeConfig from './useClientRuntimeConfig';

export interface Props {
    children: ReactNode;
}

const RuntimeConfigProvider = ({ children }: Props) => {
    const { config: clientConfig, setConfig } = useClientRuntimeConfig();
    const cashedConfig = getCachedRuntimeConfig();

    const fetcher = async () => {
        const fetchUrl = '/api/runtime-config/';
        const request = axios.get<RuntimeConfig>(fetchUrl);

        const { data } = await request;
        return data;
    };

    const { data: fetchedConfig } = useSWR('config', fetcher);

    useEffect(() => {
        if (!global.window || !fetchedConfig) return;

        if (!isEqual(clientConfig, fetchedConfig)) setConfig(fetchedConfig);

        if (isEqual(fetchedConfig, cashedConfig)) return;

        setCachedRuntimeConfig(fetchedConfig);
    }, [fetchedConfig, global.window, cashedConfig]);

    return <Fragment>{children}</Fragment>;
};

export default RuntimeConfigProvider;
