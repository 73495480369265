import { styled } from '@mui/material';

import { mq } from '@theme/mediaQueries';

export const Wrapper = styled('div')(() =>
    mq({
        display: 'flex',
        alignItems: 'flex-start',
        gap: '16px',
        fontSize: '1rem',
        padding: '10px',
    })
);

export const Info = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    })
);

export const Title = styled('h3')(({ theme }) =>
    mq({
        ...theme.fonts.body_1,
        color: theme.colors.primaryText,
    })
);

export const IconWrapper = styled('div')(() =>
    mq({
        width: '54px',
        height: '54px',
    })
);
